define("discourse/plugins/discourse-locations/discourse/templates/users/user-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="container users-nav">
    <ul class="nav nav-pills">
      <li>
        {{#link-to "users.index"}}
          {{i18n "directory.list.title"}}
        {{/link-to}}
      </li>
      <li>
        {{#link-to "users.user-map"}}
          {{i18n "directory.map.title"}}
        {{/link-to}}
      </li>
      <li>
        <a
          href="/my/preferences/profile"
          title={{i18n "directory.map.user_prefs_link.title"}}
        >{{i18n "directory.map.user_prefs_link.text"}}</a>
      </li>
    </ul>
  </div>
  <div class="users-map-container">
    <div class="map-component map-container">
      <LocationsMap @mapType="userList" />
    </div>
  </div>
  */
  {
    "id": "gY9lLLSH",
    "block": "[[[10,0],[14,0,\"container users-nav\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],[\"users.index\"],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"directory.list.title\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],[\"users.user-map\"],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"directory.map.title\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"/my/preferences/profile\"],[15,\"title\",[28,[37,1],[\"directory.map.user_prefs_link.title\"],null]],[12],[1,[28,[35,1],[\"directory.map.user_prefs_link.text\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"users-map-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"map-component map-container\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@mapType\"],[\"userList\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\",\"i18n\",\"locations-map\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/users/user-map.hbs",
    "isStrictMode": false
  });
});